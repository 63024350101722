.PageLayoutContent {
  margin: 0 1rem;
}
.PageLayoutContent_is-fit {
  max-width: 400px;
}
.PageLayoutContent_offset-top {
  margin-top: 1rem;
}
.PageLayoutContent_offset-bottom {
  margin-bottom: 1rem;
}
@media only screen and (max-width: 768px) {
  .PageLayoutContent_is-mob-full-width {
    margin-left: 0;
    margin-right: 0;
  }
}
