.PageLayoutAside {
  box-sizing: border-box;
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 768px) {
  .PageLayoutAside {
    overflow-y: auto;
    height: 100%;
    border-radius: 0;
  }
}
.PageLayoutAside_inner-offset {
  padding: 1.5rem;
}
.PageLayoutAside_color-primary {
  background-color: #d4b390;
}
.PageLayoutAside_color-gray {
  background-color: #eae9e9;
}
.PageLayoutAside_is-sticky {
  position: sticky;
  top: 100px;
  margin-bottom: 25px;
}
@media only screen and (max-width: 768px) {
  .PageLayoutAside_is-sticky {
    position: static;
    top: 0;
    margin-bottom: 0;
  }
}
.PageLayoutAside_without-padding {
  padding: 0;
}
.PageLayoutAside__header {
  margin-bottom: 1rem;
}
.PageLayoutAside__body {
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
