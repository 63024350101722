.PageLayoutAsideLink {
  display: block !important;
  font-size: 15px;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  cursor: pointer;
  border-left: 2px solid transparent;
  margin: 3px 0;
  transition: 200ms all;
  color: #fff;
  text-decoration: none;
}
.PageLayoutAsideLink:hover,
.PageLayoutAsideLink.active {
  text-decoration: none;
  border-color: #fff;
}
