.PageLayout {
  padding-top: 1.5rem;
}
@media only screen and (max-width: 768px) {
  .PageLayout__up {
    cursor: pointer;
    position: fixed;
    width: 50px;
    height: 50px;
    background-color: #ae937c;
    bottom: 65px;
    right: 15px;
    border-radius: 50%;
    padding: 10px;
    box-sizing: border-box;
    z-index: 98;
    box-shadow: 0 0 3px -1px;
    transition: transform 250ms ease-in-out;
    transform: translateY(150px);
  }
  .PageLayout__up:after {
    content: '';
    display: block;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgNDkyLjAwMiA0OTIuMDAyIiBmaWxsPSIjZmZmZmZmIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0OTIuMDAyIDQ5Mi4wMDI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8Zz4KCQk8cGF0aCBkPSJNNDg0LjEzNiwzMjguNDczTDI2NC45ODgsMTA5LjMyOWMtNS4wNjQtNS4wNjQtMTEuODE2LTcuODQ0LTE5LjE3Mi03Ljg0NGMtNy4yMDgsMC0xMy45NjQsMi43OC0xOS4wMiw3Ljg0NAoJCQlMNy44NTIsMzI4LjI2NUMyLjc4OCwzMzMuMzMzLDAsMzQwLjA4OSwwLDM0Ny4yOTdjMCw3LjIwOCwyLjc4NCwxMy45NjgsNy44NTIsMTkuMDMybDE2LjEyNCwxNi4xMjQKCQkJYzUuMDY0LDUuMDY0LDExLjgyNCw3Ljg2LDE5LjAzMiw3Ljg2czEzLjk2NC0yLjc5NiwxOS4wMzItNy44NmwxODMuODUyLTE4My44NTJsMTg0LjA1NiwxODQuMDY0CgkJCWM1LjA2NCw1LjA2LDExLjgyLDcuODUyLDE5LjAzMiw3Ljg1MmM3LjIwOCwwLDEzLjk2LTIuNzkyLDE5LjAyOC03Ljg1MmwxNi4xMjgtMTYuMTMyCgkJCUM0OTQuNjI0LDM1Ni4wNDEsNDk0LjYyNCwzMzguOTY1LDQ4NC4xMzYsMzI4LjQ3M3oiLz4KCTwvZz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K);
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .PageLayout__up_visible {
    transform: translateY(0);
  }
}
.PageLayout__breadcrumbs {
  margin: 0 1rem 1.5rem 1rem;
}
@media only screen and (max-width: 768px) {
  .PageLayout__breadcrumbs {
    margin: 0 1rem 1rem 1rem;
  }
}
.PageLayout__content {
  width: 100%;
}
.PageLayout__section {
  display: flex;
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .PageLayout__section_aside-right {
    flex-direction: column-reverse;
  }
}
.PageLayout__section_aside-right .PageLayout__content {
  width: calc(100% - 270px - 1rem);
}
@media only screen and (max-width: 768px) {
  .PageLayout__section_aside-right .PageLayout__content {
    width: 100%;
  }
}
.PageLayout__section_aside-left .PageLayout__content {
  width: calc(100% - 270px - 1rem);
}
@media only screen and (max-width: 768px) {
  .PageLayout__section_aside-left .PageLayout__content {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .PageLayout__section_aside-right-mob-hidden .PageLayout__aside-right {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .PageLayout__section_aside-right-mob-hidden .PageLayout__content {
    width: 100%;
  }
}
.PageLayout__aside-left {
  max-width: calc(270px - 1rem);
  min-width: calc(200px - 1rem);
  width: 30vw;
  margin-left: 1rem;
  padding-right: 1rem;
  box-sizing: border-box;
}
@media only screen and (max-width: 768px) {
  .PageLayout__aside-left {
    display: none;
  }
}
.PageLayout__aside-right {
  width: 270px;
  margin-right: 1rem;
  padding-left: 1rem;
  box-sizing: border-box;
}
@media only screen and (max-width: 768px) {
  .PageLayout__aside-right {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
    transition: transform 250ms cubic-bezier(0.25, 0.8, 0.25, 1);
    background-color: #fff;
    height: 100vh;
    width: 80%;
    max-width: 300px;
    transform: translateX(110%);
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0;
    padding: 0;
  }
}
@media only screen and (max-width: 400px) {
  .PageLayout__aside-right {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .PageLayout__aside-right_open {
    transform: translateX(0);
  }
}
.PageLayout__header {
  margin: 0 1rem 2rem 1rem;
  display: flex;
  align-items: baseline;
}
@media only screen and (max-width: 768px) {
  .PageLayout__header {
    margin: 0 1rem 1rem 1rem;
  }
}
.PageLayout__sub-header {
  position: relative;
  top: -10px;
  padding-left: 0.5rem;
}
.PageLayout__overlay {
  z-index: 100;
}
