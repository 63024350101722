.Paragraph {
  margin: 0;
  margin-bottom: 1rem;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #544f4e;
  line-height: 1.5;
}
.Paragraph_align-left {
  text-align: left;
}
.Paragraph_align-right {
  text-align: right;
}
.Paragraph_align-center {
  text-align: center;
}
.Paragraph_center {
  text-align: center;
}
.Paragraph_italic {
  font-style: italic;
}
.Paragraph_level-1 {
  font-size: 10px;
}
.Paragraph_level-2 {
  font-size: 12px;
}
.Paragraph_level-3 {
  font-size: 14px;
}
.Paragraph_level-4 {
  font-size: 16px;
}
.Paragraph_level-5 {
  font-size: 18px;
}
.Paragraph_level-6 {
  font-size: 20px;
}
.Paragraph_level-7 {
  font-size: 30px;
}
.Paragraph_color-white {
  color: #fff;
}
